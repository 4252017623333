
import { Component, Vue } from "vue-property-decorator";
import api from "../../api/general";

@Component({})
export default class VerifyAdminForm extends Vue {
  password = "";

  showFailure = false;
  disableSubmit = false;

  clearForm() {
    this.password = "";
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    api.verifyPassword(btoa(this.password), true).then((res) => {
      if (res.success) {
        this.$emit("update");
      } else {
        this.showFailure = true;
      }
      this.clearForm();
      this.disableSubmit = false;
    });
  }
}
