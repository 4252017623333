
import { Component, Prop, Vue } from "vue-property-decorator";
import admin from "../../api/admin";

@Component({})
export default class DeleteUserForm extends Vue {
  @Prop(Object) user!: User;

  showFailure = false;
  disableSubmit = false;

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    if (!this.user?.userId) {
      return;
    }

    admin.deleteUsers([this.user.userId]).then((data) => {
      if (data.success) {
        this.$emit("delete-success");
      } else {
        this.showFailure = true;
      }
      this.disableSubmit = false;
    });
  }
}
