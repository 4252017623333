
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserSearch extends Vue {
  @Prop(Array) users!: User[];

  text = "";

  @Watch("text")
  onTextChanged(val: string) {
    val = val.toLowerCase();
    const toRemove: User[] = [];
    this.users?.forEach((user) => {
      if (
        !(
          user.userId.toLowerCase().includes(val) ||
          user.name.toLowerCase().includes(val) ||
          user.barcode?.toString().toLowerCase().includes(val) ||
          user.team?.toLowerCase().includes(val) ||
          user.sectionId.toLowerCase().includes(val) ||
          user.role.toLowerCase().includes(val)
        )
      ) {
        toRemove.push(user);
      }
    });
    const shownUsers = Array.from(this.users);
    toRemove?.forEach((elem) => {
      const index = shownUsers.indexOf(elem);
      if (index !== -1) {
        shownUsers.splice(index, 1);
      }
    });

    this.$emit("update", shownUsers);
  }
}
