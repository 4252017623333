
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ShowingBlurb extends Vue {
  @Prop(Number) currentPage!: number;
  @Prop(Number) perPage!: number;
  @Prop(Array) all!: any[];
  @Prop({ required: false, type: Array, default: null }) shown!: any[];
}
