
import VerifyAdminForm from "../../components/admin/VerifyAdminForm.vue";
import UsersList from "../../components/users/UsersList.vue";
import LogsList from "../../components/logs/LogsList.vue";
import { Component, Vue } from "vue-property-decorator";
import admin from "../../api/admin";

@Component({
  components: {
    VerifyAdminForm,
    UsersList,
    LogsList,
  },
})
export default class Admin extends Vue {
  sections: Section[] | null = null;

  verified = false;
  hasLoaded = false;

  allLogs: Log[] = [];
  shownLog: Log[] = [];

  paging = { per: 1, current: 1, total: 1 };
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = "signIn";
  sortDesc = true;
  fields = [
    { key: "name", label: "Name", sortable: true },
    { key: "signIn", label: "Signed In At", sortable: true },
  ];

  mounted(): void {
    this.verified = false;
    this.$bvModal.show("admin-modal");
  }

  loadSections() {
    admin.getSections().then((res) => {
      if (res.success && res.data) {
        const data = res.data as { sections: Section[] };
        this.sections = data.sections;
        this.hasLoaded = true;
      }
    });
  }

  adminVerified() {
    this.$bvModal.hide("admin-modal");
    this.verified = true;
    this.hasLoaded = false;
    this.loadSections();
  }
}
