
import { Component, Prop, Vue } from "vue-property-decorator";
import admin from "../../api/admin";

@Component({})
export default class DeleteAllSectionsForm extends Vue {
  @Prop(Number) sectionCount!: number;

  showFailure = false;
  disableSubmit = false;

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    admin.deleteSections().then((data) => {
      if (data.success) {
        this.$emit("delete-success");
      } else {
        this.showFailure = true;
      }
      this.disableSubmit = false;
    });
  }
}
