import { get, post, put, del, StandardResponse } from "../shared/requests";

async function addSections(sections: {
  sections: Section[];
}): Promise<StandardResponse> {
  return await post(`/sections`, sections);
}

async function getSections(): Promise<StandardResponse> {
  return await get(`/sections`);
}

async function deleteSections(
  sectionIds?: string[]
): Promise<StandardResponse> {
  if (sectionIds != undefined) {
    return await del(`/sections?ids=${btoa(JSON.stringify(sectionIds))}`);
  } else {
    return await del(`/sections`);
  }
}

async function addUsers(users: { users: User[] }): Promise<StandardResponse> {
  return await post(`/users`, users);
}

async function getUsers(userIds?: string[]): Promise<StandardResponse> {
  if (userIds != undefined) {
    return await get(`/users?ids=${btoa(JSON.stringify(userIds))}`);
  } else {
    return await get(`/users`);
  }
}

async function deleteUsers(userIds?: string[]): Promise<StandardResponse> {
  if (userIds != undefined) {
    return await del(`/users?ids=${btoa(JSON.stringify(userIds))}`);
  } else {
    return await del(`/users`);
  }
}

async function getLogs(): Promise<StandardResponse> {
  return await get(`/logs`);
}

async function deleteLogs(): Promise<StandardResponse> {
  return await del(`/logs`);
}

async function updateTABarcode(barcode: string): Promise<StandardResponse> {
  return post("/users?updateTA=true", { barcode: barcode });
}
export default {
  addSections,
  getSections,
  deleteSections,
  addUsers,
  getUsers,
  getLogs,
  deleteUsers,
  deleteLogs,
  updateTABarcode,
};
