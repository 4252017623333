
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import shop from "../../api/shop";
import { barcodeLength } from "../../utils/config";

@Component({})
export default class SignOutForm extends Vue {
  @Prop(Object) readonly user!: User | null;

  showFailure = false;
  failureReason = "";
  disableSubmit = false;

  barcode = "";
  barcodeFailureReason = "";
  showBarcodeFailure = false;
  validBarcode = false;

  @Watch("barcode")
  onBarcodeChanged(val: string) {
    this.validBarcode = false;
    if (val.length == barcodeLength && Number.isInteger(Number(val))) {
      this.validBarcode = true;
      this.barcodeFailureReason = "";
      this.showBarcodeFailure = false;
    } else if (val.length > 0) {
      this.showBarcodeFailure = true;
      this.barcodeFailureReason = `Barcode values are integers with ${barcodeLength} digits.`;
    } else {
      this.showBarcodeFailure = false;
      this.barcodeFailureReason = "";
    }
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;

    if (this.user != null) {
      if (
        this.user.role.toLowerCase() in ["ta", "instructor"] ||
        this.user.barcode === this.barcode
      ) {
        shop.signOut(this.user.userId).then((res) => {
          if (res.success) {
            this.$emit("update");
          } else {
            this.showFailure = true;
            this.failureReason = res.error as string;
          }
        });
      } else {
        this.showFailure = true;
        this.failureReason = "Invalid barcode entered.";
      }
    } else {
      shop.signOutAll(this.barcode).then((res) => {
        if (res.success) {
          this.$emit("update");
        } else {
          this.showFailure = true;
          this.failureReason = res.error as string;
        }
      });
    }
  }
}
