import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Forbidden from "../views/errors/Forbidden.vue";
import NotFound from "../views/errors/NotFound.vue";
import Admin from "../views/admin/Admin.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home, meta: { title: "Home" } },
  { path: "/home", component: Home, meta: { title: "Home" } },
  { path: "/admin", component: Admin, meta: { title: "Admin" } },
  {
    path: "/forbidden",
    component: Forbidden,
    meta: { title: "Forbidden" },
  },
  {
    path: "*",
    component: NotFound,
    meta: { title: "Not Found" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title
      ? to.meta.title + " - Shop Management"
      : "Shop Management";
  });
});

export default router;
