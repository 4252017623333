
import { Component, Vue, Watch } from "vue-property-decorator";
import admin from "../../api/admin";
import { barcodeLength } from "../../utils/config";

@Component({})
export default class UpdateTABarcodeForm extends Vue {
  barcode = "";
  barcodeFailureReason = "";
  showBarcodeFailure = false;
  validBarcode = false;

  showFailure = false;
  disableSubmit = false;

  @Watch("barcode")
  onBarcodeChanged(val: string) {
    this.validBarcode = false;
    if (val.length == barcodeLength && Number.isInteger(Number(val))) {
      this.validBarcode = true;
      this.barcodeFailureReason = "";
      this.showBarcodeFailure = false;
    } else if (val.length > 0) {
      this.showBarcodeFailure = true;
      this.barcodeFailureReason = `Barcode values are integers with ${barcodeLength} digits.`;
    } else {
      this.showBarcodeFailure = false;
      this.barcodeFailureReason = "";
    }
  }

  clearForm() {
    this.barcode = "";
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    admin.updateTABarcode(this.barcode).then((res) => {
      if (res.success) {
        this.$emit("update");
      } else {
        this.showFailure = true;
      }
      this.clearForm();
      this.disableSubmit = false;
    });
  }
}
