
import { Component, Vue, Watch } from "vue-property-decorator";
import TableWrapper from "../../components/utils/TableWrapper.vue";
import UserSearch from "../../components/users/UserSearch.vue";
import SignOutForm from "../../components/users/SignOutForm.vue";
import api from "../../api/general";
import shop from "../../api/shop";
import Store from "../../shared/store";
import { barcodeLength } from "../../utils/config";
import AcknowledgeForm from "../../components/users/AcknowledgeForm.vue";

@Component({
  components: {
    TableWrapper,
    UserSearch,
    SignOutForm,
    AcknowledgeForm,
  },
})
export default class Home extends Vue {
  barcode = "";
  barcodeFailureReason = "";
  showBarcodeFailure = false;
  validBarcode = false;

  action = "";
  failureReason = "";
  showSuccess = false;
  showFailure = false;

  activeUsers: User[] = [];
  shownUsers: User[] = [];

  selectedUser: User | null = null;

  paging = { per: 1, current: 1, total: 1 };

  loadedStudents = false;
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = "signedInTime";
  sortDesc = false;
  fields = [
    { key: "name", label: "Name", sortable: true },
    { key: "team", label: "Team", sortable: true },
    { key: "signedInTime", label: "Signed In At", sortable: true },
  ];

  update(shownUsers: User[]) {
    this.shownUsers = shownUsers;
  }

  @Watch("barcode")
  onBarcodeChanged(val: string) {
    this.validBarcode = false;
    if (val.length == barcodeLength && Number.isInteger(Number(val))) {
      this.validBarcode = true;
      this.barcodeFailureReason = "";
      this.showBarcodeFailure = false;
    } else if (val.length > 0) {
      this.showBarcodeFailure = true;
      this.barcodeFailureReason = `Barcode values are integers with ${barcodeLength} digits.`;
    } else {
      this.showBarcodeFailure = false;
      this.barcodeFailureReason = "";
    }
  }

  mounted(): void {
    if (Store.get("verified") != true) {
      if ("p" in this.$route.query) {
        const p = this.$route.query.p.toString();
        api.verifyPassword(p).then((res) => {
          if (res.status != 200) {
            this.$router.replace("/forbidden");
          } else {
            Store.set("verified", true);
            this.$router.replace("/");
            this.loadActiveStudents();
          }
        });
      } else {
        this.$router.replace("/forbidden");
      }
    } else {
      this.$router.replace("/").catch(() => {});
      this.loadActiveStudents();
    }
  }

  loadActiveStudents() {
    this.loadedStudents = false;
    shop.getActiveUsers().then((res) => {
      if (res.success && res.data) {
        const data = res.data as { users: User[] };
        this.activeUsers = data.users;
        this.shownUsers = data.users;
      }
      this.loadedStudents = true;
    });
  }

  showModal(evt: Event) {
    evt.preventDefault();
    this.$bvModal.show("acknowledge-modal");
  }

  signIn() {
    this.$bvModal.hide("acknowledge-modal");
    this.action = "signed in";
    this.showSuccess = false;
    this.showFailure = false;
    shop.signIn(this.barcode).then((res) => {
      if (res.success) {
        this.showSuccess = true;
        this.barcode = "";
        const data = res.data as { message: string; name: string };
        if (data?.message !== "Already signed in") {
          this.loadActiveStudents();
        }
      } else {
        this.showFailure = true;
        this.barcode = "";
        this.failureReason = res.error as string;
      }
    });
  }

  signOutSuccess() {
    this.$bvModal.hide("sign-out-user-modal");
    this.$bvModal.hide("sign-out-all-users-modal");
    this.action = "signed out";
    this.showSuccess = true;
    this.loadActiveStudents();
    this.selectedUser = null;
  }

  onRowSelected(users: User[]) {
    if (users.length > 0) {
      this.selectedUser = users[0];
    } else {
      this.selectedUser = null;
    }
  }
}
