import { get, post, StandardResponse } from "../shared/requests";

async function signIn(barcode: string): Promise<StandardResponse> {
  return await post(`/signin`, { barcode: barcode });
}

async function signOut(userId: string): Promise<StandardResponse> {
  return await post(`/signout`, { userId: userId });
}

async function signOutAll(barcode: string): Promise<StandardResponse> {
  return await post(`/signout?all=true`, { barcode: barcode });
}

async function getActiveUsers(): Promise<StandardResponse> {
  return await get(`/users?active=true`);
}

export default {
  signIn,
  signOut,
  signOutAll,
  getActiveUsers,
};
