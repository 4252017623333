
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ShowingBlurb from "./ShowingBlurb.vue";

@Component({ components: { ShowingBlurb } })
export default class TableWrapper extends Vue {
  @Prop({ required: false, type: Array, default: () => [10, 25, 50] })
  pageCountOptions!: number[];
  @Prop({ required: false, type: Number, default: 10 }) defaultPerPage!: number;
  @Prop({ required: true, type: Array }) all!: any[];
  @Prop({ required: false, type: Array, default: null }) shown!: any[];

  paging = { per: 1, current: 1, total: 1 };
  @Watch("paging", { deep: true })
  onPagingChanged() {
    this.$emit("pagingChange", this.paging);
  }

  mounted() {
    this.paging.per = this.defaultPerPage;
    this.paging.total = this.shown ? this.shown.length : this.all.length;
  }

  // Recompute the total number of rows if/when that changes above
  updated() {
    this.paging.total = this.shown ? this.shown.length : this.all.length;
  }
}
