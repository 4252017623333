
import { Component, Vue, Prop } from "vue-property-decorator";
import LogSearch from "./LogSearch.vue";
import TableWrapper from "../utils/TableWrapper.vue";
import admin from "../../api/admin";
import DeleteAllLogsForm from "./DeleteAllLogsForm.vue";
import { buildSectionString } from "../../utils/genericUtils";
@Component({
  components: {
    LogSearch,
    TableWrapper,
    DeleteAllLogsForm,
  },
})
export default class LogsList extends Vue {
  @Prop(Array) readonly sections!: Section[];
  logs: Log[] = [];
  shownLogs: Log[] = [];
  showDeleteSuccess = false;

  hasMounted = false;
  loadedLogs = false;

  paging = { per: 1, current: 1, total: 1 };
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = "time";
  sortDesc = true;
  fields = [
    { key: "userId", label: "Name.#", sortable: true },
    { key: "sectionId", label: "Section", sortable: false },
    { key: "time", label: "Time", sortable: true },
    { key: "action", label: "Action", sortable: true },
  ];

  update(shownLogs: Log[]) {
    this.shownLogs = shownLogs;
  }

  mounted() {
    this.loadLogs();
    this.hasMounted = true;
  }

  loadLogs() {
    this.loadedLogs = false;
    admin.getLogs().then((res) => {
      if (res.success && res.data) {
        const data = res.data as { logs: Log[] };
        this.logs = data.logs;
        this.shownLogs = data.logs;
      }
      this.loadedLogs = true;
    });
  }

  deleteSuccess() {
    this.$bvModal.hide("delete-all-logs-modal");
    this.showDeleteSuccess = true;
    this.loadLogs();
  }

  buildSectionString(sectionId: string) {
    if (!this.sections) {
      return "";
    }
    const sectionIds = this.sections.map((sec) => sec.sectionId);
    const idx = sectionIds.indexOf(sectionId);
    if (idx >= 0) {
      const section = this.sections[idx];
      return buildSectionString(section);
    } else {
      return "";
    }
  }
}
