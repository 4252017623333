
import { Component, Vue } from "vue-property-decorator";
import admin from "../../api/admin";

@Component({})
export default class ParseUsersForm extends Vue {
  form: { file: File | null } = {
    file: null,
  };

  showSuccess = false;
  showFailure = false;
  disableSubmit = false;

  sectionsAdded = 0;

  uploadSectionTemplate: File | null = null;

  clearForm() {
    this.form = {
      file: null,
    };
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.disableSubmit = true;

    if (!this.form.file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsBinaryString(this.form.file);
    reader.addEventListener("load", async (ev) => {
      const result = ev.target?.result as string | null | undefined;

      if (result) {
        const sections = result
          .trim()
          .split("\n")
          .slice(1)
          .map((a) => a.split(","))
          .map(([sectionId, instructor, ...classTimes]) => ({
            sectionId: sectionId.trim(),
            instructor: instructor.trim(),
            classTimes: classTimes
              .filter((s) => s.trim() !== "")
              .map((s) => {
                const parts = s.split("-");
                return {
                  day: parts[0],
                  startTime: parts[1],
                  endTime: parts[2],
                };
              }),
          }));

        const sectionsPostData = {
          ...{ sections: sections },
        };

        admin.addSections(sectionsPostData).then((res) => {
          if (res.success) {
            this.clearForm();
            this.determineSuccess(res);
            this.$emit("update");
          } else {
            this.showFailure = true;
          }
          this.disableSubmit = false;
        });
      }
    });
  }

  determineSuccess(res: any) {
    this.sectionsAdded = res.data.sectionsAdded;

    if (this.sectionsAdded > 0) {
      this.showSuccess = true;
    } else {
      this.showFailure = true;
    }
  }
}
