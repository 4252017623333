import { get, StandardResponse } from "../shared/requests";

async function verifyPassword(
  p: string,
  admin: boolean = false
): Promise<StandardResponse> {
  return await get(`/verify?p=${p}&admin=${admin}`);
}

export default {
  verifyPassword,
};
