
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class LogSearch extends Vue {
  @Prop(Array) logs!: Log[];

  text = "";

  @Watch("text")
  onTextChanged(val: string) {
    val = val.toLowerCase();
    const toRemove: Log[] = [];
    this.logs?.forEach((log) => {
      if (
        !(
          log.userId.toLowerCase().includes(val) ||
          log.sectionId.toLowerCase().includes(val) ||
          log.time.toLowerCase().includes(val) ||
          log.action.toLowerCase().includes(val)
        )
      ) {
        toRemove.push(log);
      }
    });
    const shownLogs = Array.from(this.logs);
    toRemove?.forEach((elem) => {
      const index = shownLogs.indexOf(elem);
      if (index !== -1) {
        shownLogs.splice(index, 1);
      }
    });

    this.$emit("update", shownLogs);
  }
}
