
import { buildSectionString } from "../..//utils/genericUtils";
import { Component, Vue, Prop } from "vue-property-decorator";
import admin from "../../api/admin";

@Component({})
export default class ParseUsersForm extends Vue {
  @Prop(Array) readonly sections!: Section[];

  form: { file: File | null; sectionId: string } = {
    file: null,
    sectionId: "",
  };

  showSuccess = false;
  showFailure = false;
  disableSubmit = false;

  usersAdded = 0;

  uploadRosterTemplate: File | null = null;

  get sectionOptions() {
    const result: { text: string; value: string }[] = [
      { text: "Choose Section", value: "" },
    ];

    this.sections.forEach((section) => {
      if (section.sectionId) {
        result.push({
          text: buildSectionString(section),
          value: section.sectionId,
        });
      }
    });

    return result;
  }

  clearForm() {
    this.form = {
      file: null,
      sectionId: "",
    };
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.disableSubmit = true;

    if (!this.form.file || this.form.sectionId.length == 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsBinaryString(this.form.file);
    reader.addEventListener("load", async (ev) => {
      const result = ev.target?.result as string | null | undefined;

      if (result) {
        const users = result
          .trim()
          .split("\n")
          .slice(1)
          .map((a) => a.split(","))
          .map(
            ([userId, name, barcode, team, trainingLevel, role, isAdmin]) => ({
              userId: userId.trim(),
              name: name.trim(),
              barcode: barcode.trim() === "" ? "" : barcode.trim(),
              team: team.trim() === "" ? "" : team.trim(),
              trainingLevel: Number.parseInt(trainingLevel) ?? 0,
              sectionId: this.form.sectionId.trim(),
              role: role.trim().toLowerCase() as
                | "student"
                | "instructor"
                | "ta",
              isAdmin: isAdmin.trim().toLowerCase() === "true",
            })
          );

        const rosterPostData = {
          ...{ users: users },
        };

        admin.addUsers(rosterPostData).then((res) => {
          if (res.success) {
            this.clearForm();
            this.determineSuccess(res);
            this.$emit("update");
          } else {
            this.showFailure = true;
          }
          this.disableSubmit = false;
        });
      }
    });
  }

  determineSuccess(res: any) {
    this.usersAdded = res.data.usersAdded;

    if (this.usersAdded > 0) {
      this.showSuccess = true;
    } else {
      this.showFailure = true;
    }
  }
}
