
import { Component, Vue, Prop } from "vue-property-decorator";
import UserSearch from "./UserSearch.vue";
import TableWrapper from "../utils/TableWrapper.vue";
import ParseUsersForm from "./ParseUsersForm.vue";
import DeleteAllUsersForm from "./DeleteAllUsersForm.vue";
import DeleteUserForm from "./DeleteUserForm.vue";
import ParseSectionsForm from "../sections/ParseSectionsForm.vue";
import DeleteAllSectionsForm from "../sections/DeleteAllSectionsForm.vue";
import UpdateTABarcodeForm from "../admin/UpdateTABarcodeForm.vue";

import admin from "../../api/admin";
import { buildSectionString } from "../../utils/genericUtils";
import { barcodeLength } from "../../utils/config";

@Component({
  components: {
    UserSearch,
    TableWrapper,
    ParseUsersForm,
    DeleteAllUsersForm,
    DeleteUserForm,
    ParseSectionsForm,
    DeleteAllSectionsForm,
    UpdateTABarcodeForm,
  },
})
export default class UsersList extends Vue {
  @Prop(Array) readonly sections!: Section[];
  users: User[] = [];
  shownUsers: User[] = [];
  deletedUser: User | null = null;

  hasMounted = false;
  loadedUsers = false;

  paging = { per: 1, current: 1, total: 1 };
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = "userId";
  sortDesc = true;
  fields = [
    { key: "userId", label: "Name.#", sortable: true },
    { key: "name", label: "Name", sortable: true },
    { key: "barcode", label: "Barcode", sortable: false },
    { key: "team", label: "Team", sortable: true },
    { key: "trainingLevel", label: "Training Level", sortable: false },
    { key: "sectionId", label: "Section", sortable: true },
    { key: "role", label: "Role", sortable: true },
    { key: "actions", sortable: false, label: "" },
  ];

  showUserDeleteSuccess = false;
  showSectionDeleteSuccess = false;
  showUpdateBarcodeFailure = false;
  showUpdateTrainingLevelFailure = false;

  get trainingLevelOptions() {
    return [
      { text: "0", value: 0 },
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
    ];
  }

  update(shownUsers: User[]) {
    this.shownUsers = shownUsers;
  }

  buildSectionString(sectionId: string) {
    if (!this.sections) {
      return "";
    }
    const sectionIds = this.sections.map((sec) => sec.sectionId);
    const idx = sectionIds.indexOf(sectionId);
    if (idx >= 0) {
      const section = this.sections[idx];
      return buildSectionString(section);
    } else {
      return "";
    }
  }

  mounted() {
    this.loadUsers();
    this.hasMounted = true;
  }

  updateBarcode(user: User) {
    if (
      user.barcode &&
      user.barcode.length == barcodeLength &&
      Number.isInteger(Number(user.barcode))
    ) {
      admin.addUsers({ users: [user] }).then((res) => {
        if (!res.success) {
          this.showUpdateBarcodeFailure = true;
          user.barcode = "";
        }
      });
    }
  }

  updateTrainingLevel(user: User) {
    admin.addUsers({ users: [user] }).then((res) => {
      if (!res.success) {
        this.showUpdateTrainingLevelFailure = true;
      }
    });
  }

  loadUsers() {
    this.loadedUsers = false;
    admin.getUsers().then((res) => {
      if (res.success && res.data) {
        const data = res.data as { users: User[] };
        this.users = data.users;
        this.shownUsers = data.users;
      }
      this.loadedUsers = true;
    });
  }

  userDeleteSuccess() {
    this.$bvModal.hide("delete-all-users-modal");
    this.$bvModal.hide("delete-user-modal");
    this.showUserDeleteSuccess = true;
    this.hasMounted = false;
    this.loadUsers();
    this.hasMounted = true;
    this.showUserDeleteSuccess = false;
  }

  sectionDeleteSuccess() {
    this.$bvModal.hide("delete-all-sections-modal");
    this.showSectionDeleteSuccess = true;
    this.hasMounted = false;
    this.reload();
    this.hasMounted = true;
    this.showSectionDeleteSuccess = false;
  }

  taUpdateSuccess() {
    this.$bvModal.hide("update-ta-barcode-modal");
    this.hasMounted = false;
    this.reload();
    this.hasMounted = true;
    this.showSectionDeleteSuccess = false;
  }

  reload() {
    this.$emit("reload");
    this.loadUsers();
  }
}
