//Generic utils for the front end
import { BvToastOptions } from "bootstrap-vue";
import Vue from "vue";

/**
 * @function base64ToStringToFile
 *    takes a base64 string (likely from s3) and converts it to a blob file to be downloaded.
 *
 * @param base64String: the string of bytes provided by s3
 * @param fileType: the type you want the file to be. e.g. 'csv', 'pdf'
 * @returns a blob with the file
 */
export const base64StringToFile = (
  base64String: string,
  fileType: string
): Blob => {
  const binaryString = window.atob(base64String);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return new Blob([bytes], { type: `application/${fileType}` });
};

/**
 * @function successToast
 *    draws a 'success' variant toast notification
 *    hides the toast body by default
 *
 * @param component: the calling component (ussually just 'this') for injection
 * @param message: the message to be displayed
 * @param options: some toast options to override any of the default behavior of this function
 */
export const successToast = (
  component: Vue,
  message = "Operation successful!",
  options?: BvToastOptions
): void => {
  const defaultOptions: BvToastOptions = {
    solid: true,
    toaster: "feh-toaster",
    bodyClass: "d-none",
    variant: "success",
  };
  const combinedOptions: BvToastOptions = {
    ...defaultOptions,
    title: message,
    ...options,
  };
  component.$bvToast.toast(message, combinedOptions);
};

/**
 * @function failToast
 *    draws a 'fail' variant toast notification
 *    hides the toast body by default
 *
 * @param component: the calling component (ussually just 'this') for injection
 * @param message: the message to be displayed
 * @param options: some toast options to override any of the default behavior of this function
 */
export const failToast = (
  component: Vue,
  message = "The requested operation failed. Please try again later.",
  options?: BvToastOptions
): void => {
  successToast(component, message, { ...options, variant: "danger" });
};

/**
 * @function getFormattedDate
 *    Convert a SQL datestring into a more human-readable one
 *
 * @param dateString: the SQLized datestring
 */
export const formatDate = (dateString: string): string => {
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as const;
  return new Intl.DateTimeFormat("default", options).format(
    new Date(dateString)
  );
};

/**
 * @function buildSectionString
 *  takes the section object and builds a readable string
 *
 * @param section: the section to stringify
 * @returns a string with the sectin letter, time, and room.
 */
export const buildSectionString = (section: Section) => {
  return `${section.instructor}, ${section.sectionId}`;
};
